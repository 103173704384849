import React from "react"
import styled from "styled-components"

const Guide = ({ title, desc, img, reverse, height, width }) => {
  return (
    <Background reverse={reverse}>
      <MainWrapper reverse={reverse}>
        <Left>
          <Title>{title}</Title>
          <Desc>{desc}</Desc>
        </Left>
        <Right reverse={reverse}>
          <ImageContainer height={height} width={width}>
            {img}
          </ImageContainer>
        </Right>
      </MainWrapper>
    </Background>
  )
}

export default Guide

const Background = styled.div`
  background-color: ${({ reverse }) => (reverse ? "#fff" : "#f6f6f6")};
`

const MainWrapper = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  max-height: 700px;
  gap: 3rem;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4% 0px 4% 0px;
  flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};

  @media (max-width: 1334px) {
    padding-left: 10%;
    padding-right: 10%;
  }

  @media (max-width: 623px) {
    gap: 0px;
    flex-direction: column;
  }
`

const Left = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;

  @media (max-width: 623px) {
    padding: 0;
  }
`

const Right = styled.div`
  width: fit-content;
  justify-content: ${props => (!props.reverse ? "flex-end" : "flex-start")};
  height: 100%;
  @media (max-width: 623px) {
    justify-content: center;
    margin-top: 2rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.primary};
  font-size: clamp(1.3rem, 1.8vw, 2rem);
  line-height: 1.2;
  margin-bottom: 1.575rem;
  font-family: InterBold;
`

const Desc = styled.span`
  line-height: 1.6;
  font-family: Inter;
  font-size: clamp(0.9rem, 1.5vw, 1.1rem);
  letter-spacing: 0.15px;
  width: 100%;
  text-align: left;
  color: ${({ theme }) => theme.colors.fontBlack};
`

const ImageContainer = styled.div`
  max-height: ${props => props.height} px;
  max-width: ${props => props.width} px;
`
