import { keyframes } from "styled-components"

export const FadeIn = keyframes`
    from {
        opacity:0;
        transform: translate3d(0, 5rem,0);
    }
    
    to{
        opacity:1;
         transform: translate3d(0,0,0);
    }
`
