import { StaticImage } from "gatsby-plugin-image"
import React, { useState, useEffect } from "react"
import styled, { keyframes, css } from "styled-components"

function useDelayUnmount(isMounted, delayTime) {
  const [showDiv, setShowDiv] = useState(false)

  useEffect(() => {
    let timeoutId
    if (isMounted && !showDiv) {
      setShowDiv(true)
    } else if (!isMounted && showDiv) {
      timeoutId = setTimeout(() => setShowDiv(false), delayTime)
    }
    return () => clearTimeout(timeoutId)
  }, [isMounted, delayTime, showDiv])

  return showDiv
}

const LightBox = ({ children, alt, index }) => {
  const [isOpen, setIsOpen] = useState(false)
  const showDiv = useDelayUnmount(isOpen, 280)

  const toggleIsOpen = e => {
    e.preventDefault()
    setIsOpen(!isOpen)
  }

  const renderImage = () => {
    switch (index) {
      case 1:
        return <StaticImage quality={100} src="../../images/Step1.png" alt={alt} />
      case 2:
        return <StaticImage quality={100} src="../../images/Step2.png" alt={alt} />
      case 3:
        return <StaticImage quality={100} src="../../images/Step3.png" alt={alt} />
      case 4:
        return <StaticImage quality={100} src="../../images/Step4.png" alt={alt} />
      case 5:
        return <StaticImage quality={100} src="../../images/Step5.png" alt={alt} />
      default:
        return <></>
    }
  }

  return (
    <Wrapper onClick={toggleIsOpen} disableBorder={index === 1}>
      {children}
      {showDiv ? (
        <Box onClick={toggleIsOpen}>
          <AnimatorDiv isOpen={isOpen}>{renderImage()}</AnimatorDiv>
        </Box>
      ) : null}
    </Wrapper>
  )
}

export default LightBox

const FadeIn = keyframes`
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
`

const Expand = keyframes`
    from {
        width: 0px;
        height: 0px;
    }
    to{
        height: 100%;
        width: 100%;
    }
`

const Deduct = keyframes`
    from {
        height: 100%;
        width: 100%;
    }
    to {
        width: 0px;
        height: 0px;
    }
`
const animateFadeIn = css`
  ${FadeIn} 300ms ease-in
`

const animateIn = css`
  ${Expand} 300ms ease-in
`

const animateOut = css`
  ${Deduct} 300ms ease-out
`

const Wrapper = styled.div`
  border: ${({ theme, disableBorder }) =>
    disableBorder ? "none" : `1px solid ${theme.colors.gray + "30"}`};
  border-radius: 12px;

  &:hover {
    cursor: pointer;
  }
`

const AnimatorDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${({ isOpen }) => (isOpen ? animateIn : animateOut)};
`

const Box = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding-right: 20px;
  padding-left: 20px;
  height: 100vh;
  width: 100wv;
  background-color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${animateFadeIn};
`
