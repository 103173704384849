import { StaticImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import styled from "styled-components"
import LightBox from "../LightBox/LightBox"
import RightArrow from "../../assets/arrow-right-long.svg"
import LeftArrow from "../../assets/arrow-left-long.svg"
import { theme } from "../../theme"
import { FadeIn } from "../../components/animations/FadeIn"
const DetailedReport = () => {
  const instructions = [
    {
      id: 1,
      title: "Terms",
      description: `Get a auick overview of how vour too combetitors structure their articles, and how they make use of headers
      Use this as further inspiration for your content.`,
    },
    {
      id: 2,
      title: "Inspiration",
      description: `FAQ provides you with questions from Google's "People Also Ask," and it shows you what else people want to know about your target keywords. You also have short answers to those questions, so you don't have to go back and forth and waste time. \n Once you click on the Inspiration tab, you will find out all about additional KWs you can rank for. \n Some of those sub-keywords will help you get closer to discovering user intent and provide you with potential subheading ideas.`,
    },
    {
      id: 3,
      title: "Outlines",
      description: `Get a quick overview of how your top competitors structure their articles, and how they make use of headers.
Use this as further inspiration for your content.`,
    },
    {
      id: 4,
      title: "WordPress Integration",
      description: `Get an evaluation of the content score of your competitors, so that you know where the bar is set, and where you have to get.`,
    },
  ]

  const [activeInstruction, setActiveInstruction] = useState(1)

  const goNext = () => {
    if (activeInstruction === 4) {
      return
    }
    setActiveInstruction(prev => prev + 1)
  }

  const goBack = () => {
    if (activeInstruction === 1) {
      return
    }
    setActiveInstruction(prev => prev - 1)
  }

  const renderImage = () => {
    switch (activeInstruction) {
      case 1:
        return (
          <StaticImage
            quality={100}
            src="../../images/Detail1.png"
            alt="keyword-research"
            width={360}
            height={647}
            layout="constrained"
            style={{
              borderRadius: "12px",
              border: `1px solid ${theme.colors.gray + "30"}`,
            }}
          />
        )
      case 2:
        return (
          <StaticImage
            src="../../images/Detail2.png"
            alt="detailed-report"
            quality={100}
            width={360}
            height={647}
            layout="constrained"
            style={{ borderRadius: "12px" }}
          />
        )
      case 3:
        return (
          <StaticImage
            src="../../images/Detail3.png"
            alt="ai-module"
            quality={100}
            width={360}
            height={647}
            layout="constrained"
            style={{ borderRadius: "12px" }}
          />
        )
      case 4:
        return (
          <StaticImage
            quality={100}
            src="../../images/Detail4.png"
            alt="wordpress-integration"
            layout="constrained"
            width={360}
            height={647}
            style={{ borderRadius: "12px" }}
          />
        )

      default:
        return <></>
    }
  }

  return (
    <MainWrapper>
      <ContentWrapper>
        <Title>Detailed report</Title>
        <ContentBox>
          <LeftBox>
            <LightBox alt="instructions" index={activeInstruction}>
              <div>{renderImage()}</div>
            </LightBox>
            <CurrentNumberContainer className="fade-up">
              <CurrentNumberBox>
                <CurrentNumber>{`${activeInstruction}/5`}</CurrentNumber>
              </CurrentNumberBox>
            </CurrentNumberContainer>
          </LeftBox>
          <InstructionsBox>
            {instructions.map(item => {
              return (
                <Instruction
                  key={item.id}
                  onMouseEnter={() => setActiveInstruction(item.id)}
                >
                  <NumberContainer>
                    <NumberBox active={activeInstruction === item.id}>
                      <Number active={activeInstruction === item.id}>
                        {item.id}
                      </Number>
                    </NumberBox>
                  </NumberContainer>
                  <TextContainer>
                    <InstructionTitle active={activeInstruction === item.id}>
                      {item.title}
                    </InstructionTitle>
                    <InstructionDescription
                      active={activeInstruction === item.id}
                    >
                      {item.description}
                    </InstructionDescription>
                  </TextContainer>
                </Instruction>
              )
            })}
            <ArrowBox>
              <ArrowButton disabled={activeInstruction === 1} onClick={goBack}>
                <LeftArrow color={theme.colors.primary} />
              </ArrowButton>
              <ArrowButton disabled={activeInstruction === 5} onClick={goNext}>
                <RightArrow color={theme.colors.primary} />
              </ArrowButton>
            </ArrowBox>
          </InstructionsBox>
        </ContentBox>
      </ContentWrapper>
    </MainWrapper>
  )
}

export default DetailedReport

const MainWrapper = styled.section`
  background-color: #f6f6f6;
`

const ContentWrapper = styled.div`
  padding: 3rem 0px 3rem 0px;
  max-width: 1280px;
  margin: 0 auto !important;
  float: none !important;

  @media (max-width: 1334px) {
    padding-left: 10%;
    padding-right: 10%;
  }

  @media (min-width: 1024px) {
    padding-top: 120px;
    padding-bottom: 120px;
  }
`

const Title = styled.h2`
  font-size: clamp(1.2rem, 2.5vw, 2rem);
  padding-bottom: 3rem;
  font-family: InterBold;
  text-align: left;
  color: ${({ theme }) => theme.colors.headlineBlack};
`

const ContentBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;

  @media (max-width: 1334px) {
    flex-direction: column;
    gap: 60px;
  }
`

const LeftBox = styled.div`
  display: flex;
  height: 100%;
  width: 40%;
  position: relative;

  @media (max-width: 1334px) {
    width: 100%;
  }
`

const CurrentNumberContainer = styled.div`
  position: absolute;
  bottom: -35px;
  left: 35px;
  display: flex;
  width: auto;
  justify-content: center;
  padding-top: 10px;
  @media (max-width: 1334px) {
    padding-top: 0px;
  }
`

const CurrentNumberBox = styled.div`
  display: flex;
  width: 70px;
  height: 70px;
  border-radius: 80px;
  background-color: ${({ theme }) => theme.colors.primary};
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 0px 8px rgba(24, 197, 149, 0.3);
`

const CurrentNumber = styled.span`
  font-size: 1.2rem;
  font-family: Inter;
  letter-spacing: -0.03em;
  line-height: 1.5;
  text-align: center;
  color: ${({ theme }) => theme.colors.headlineWhite};
`

const InstructionsBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  animation: ${FadeIn} 1.2s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
  @media (max-width: 1334px) {
    gap: 20px;
  }
`

const Instruction = styled.div`
  display: flex;
  padding: 20px 0px;
  width: 100%;
  gap: 20px;
  cursor: pointer;
  @media (max-width: 1334px) {
  }
`

const NumberContainer = styled.div`
  display: flex;
  width: auto;
  justify-content: center;
  padding-top: 10px;
  @media (max-width: 1334px) {
    padding-top: 0px;
  }
`

const NumberBox = styled.div`
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: ${({ theme, active }) =>
    active ? theme.colors.primary : theme.colors.gray + "30"};
  justify-content: center;
  align-items: center;

  box-shadow: ${({ active }) =>
    active
      ? "0px 0px 0px 8px rgba(24, 197, 149, 0.3);"
      : "0px 0px 0px 8px rgba(146, 146, 146, 0.1);"};
`

const Number = styled.span`
  font-size: 1.2rem;
  font-family: InterBold;
  letter-spacing: -0.03em;
  line-height: 1.5;
  text-align: center;
  color: ${({ theme, active }) =>
    active ? theme.colors.headlineWhite : theme.colors.primary};
`

const TextContainer = styled.div`
  display: flex;
  width: 80%;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 10px;
`

const InstructionTitle = styled.span`
  width: 100%;
  font-size: clamp(1rem, 2vw, 1.2rem);
  font-family: Inter500;
  letter-spacing: -0.03em;
  line-height: 1.5;
  text-align: left;
  color: ${({ theme, active }) =>
    active ? theme.colors.primary : theme.colors.gray};
`

const InstructionDescription = styled.span`
  width: 100%;
  font-size: clamp(0.4rem, 2vw, 0.8rem);
  font-family: Inter;
  letter-spacing: -0.03em;
  line-height: 1.5;
  text-align: left;
  color: ${({ theme, active }) =>
    active ? theme.colors.fontBlack : theme.colors.gray + "CC"};
`

const ArrowBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`

const ArrowButton = styled.div`
  height: 50px;
  width: 50px;
  cursor: pointer;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  &:hover {
    filter: ${({ disabled }) =>
      disabled ? "brightness(1.1)" : "brightness(1.1)"};
  }
`
