import React from "react"
import Layout from "../components/Layout"
import styled from "styled-components"
import { CustomThemeProvider } from "../theme/ThemeProvider"
import Guide from "../components/Features/Guide"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import DetailedReport from "../components/Features/DetailedReport"

const features = ({ data }) => {
  const renderFeatures = data?.images?.nodes
    .sort((a, b) => (a.frontmatter.order > b.frontmatter.order ? 1 : -1))
    .map((item, index) => {
      if (item.frontmatter.img === null) return null
      else
        return (
          <Guide
            title={item.frontmatter.title}
            desc={item.frontmatter.desc}
            img={
              <GatsbyImage
                image={getImage(item.frontmatter.img.childImageSharp)}
                alt={item.frontmatter.alt}
                style={{
                  maxHeight: item.frontmatter.eight,
                  maxWidth: item.frontmatter.width,
                }}
              />
            }
            alt={item.frontmatter.alt}
            reverse={item.frontmatter.reverse}
            key={item.frontmatter.title}
            height={item.frontmatter.height}
            width={item.frontmatter.width}
          />
        )
    })
  return (
    <CustomThemeProvider>
      <Seo title={"How to create high-ranking content fast?"} />
      <Header>
        <HeadingContainer>
          <Title>How to create high-ranking content fast?</Title>
          <Desc>
            SEO know-how combined with AI makes your content creation easy and
            efficient
          </Desc>
        </HeadingContainer>
        <GatsbyImage
          image={getImage(data.features.childImageSharp)}
          alt="feat"
          style={{
            position: "absolute",
            inset: "0",
            zIndex: "-2",
          }}
        />
      </Header>
      <Layout>
        <Background>
          <MainWrapper>
            <FirstSection>
              <LeftDiv>
                <HeadingWrapper>
                  <Heading>
                    <Heading>
                      As an SEO content writer, you have a list of things to do
                      for each article:
                    </Heading>
                  </Heading>
                </HeadingWrapper>
                <List List>
                  <ListItems>
                    <Span>
                      Keyword research:Find a keyword worth writing content for.
                    </Span>
                  </ListItems>
                  <ListItems>
                    <Span>Analyze top competitors' content</Span>
                  </ListItems>
                  <ListItems>
                    <Span>Write content that outshines them.</Span>
                  </ListItems>
                  <ListItems>
                    <Span>Post it to your website.</Span>
                  </ListItems>
                  <ListItems>
                    <Span>
                      Continously follow up to see if you've started ranking.
                    </Span>
                  </ListItems>
                </List>
                <ListFooter>
                  <StyledTitle>
                    Robinize is an all-in-one tool that speeds up this process
                    10X.
                  </StyledTitle>
                  <StyledText>Explore how:</StyledText>
                </ListFooter>
              </LeftDiv>
              <MountainDiv>
                <GatsbyImage
                  image={getImage(data.mountain.childImageSharp)}
                  alt="mountain"
                  placeholder="blurred"
                />
              </MountainDiv>
            </FirstSection>
          </MainWrapper>
          {renderFeatures}
        </Background>
        <DetailedReport />
        <Guide
          title={`How Robinize makes sure you only get the best recommendations for your content`}
          desc={`Content scoring provides you with a basis and objective goal to aim for.
            Robinize grades your content and the content of your competitors on a scale from 1-10. It recommends the length
            of your content and measures your word count.
            The readability level determines text comprehensiveness by using Flesch reading ease. Add a human factor, aka the
            best writer, and you are set for greatness.`}
          img={
            <StaticImage
              quality={100}
              src={"../images/recommendations.png"}
              alt={"meta-tags"}
              width={512}
              height={312}
              layout="constrained"
              style={{ borderRadius: "12px" }}
            />
          }
          alt={
            "how-robinize-makes-sure-you-only-get-the-best-recommendations-for-your-content"
          }
          reverse={false}
          key={
            "how-robinize-makes-sure-you-only-get-the-best-recommendations-for-your-content"
          }
          width={512}
          height={312}
        />
        <AIContentBuilder />
        <Guide
          title={`Don't forget the headline and meta description.`}
          desc={
            "Every article needs them and they are super important. Robinize makes sure both of them are within the SEO required range of characters. \n All in all, Robinize provides you with resources that amke your content writing process easier, better and SEO optimized"
          }
          img={
            <StaticImage
              quality={100}
              src={"../images/outline-meta-tags.png"}
              alt={"meta-tags"}
              width={620}
              height={554}
              layout="constrained"
              style={{ borderRadius: "12px" }}
            />
          }
          alt={"meta-tags"}
          reverse={false}
          key={"meta-tags"}
          width={620}
          height={554}
        />
        <Guide
          title={`Website integration`}
          desc={`Robinize offers an integration feature that enables you to post articles directly from our platform into your CMS. \n Currently, we only support WordPress, but we are actively working on expanding our integrations to include other popular platforms.`}
          img={
            <StaticImage
              quality={100}
              src={"../images/report-menu.png"}
              alt={"website-integration"}
              width={422}
              height={371}
              layout="constrained"
              style={{ borderRadius: "12px" }}
            />
          }
          alt={"website-integration"}
          reverse={true}
          key={"website-integration"}
          width={422}
          height={371}
        />
        <Guide
          title={`Rank Tracking`}
          desc={`Don't just write content, follow up on how you're doing in Google. With our rank tracking module, you can follow your position over time, for any given keyword.`}
          img={
            <StaticImage
              quality={100}
              src={"../images/ranking-graph.png"}
              alt={"rank-tracking"}
              width={794}
              height={516}
              layout="constrained"
              style={{ borderRadius: "12px" }}
            />
          }
          alt={"rank-tracking"}
          reverse={false}
          key={"rank-tracking"}
          width={794}
          height={516}
        />
      </Layout>
    </CustomThemeProvider>
  )
}

export default features

const AIContentBuilder = () => {
  return (
    <BuilderBackground>
      <ContentBuilder>
        <ContentTitle>AI content builder</ContentTitle>
        <ContentDesc>
          Our Al has analize the questions your competitors address in their
          content. You can select the questions you want your article to answer,
          structure them as you see fit, and generate content for each question.
          If you're not satisfied with the initial content, simply click on the
          magic wand to tell the Al how to modify it, or ask it to regenerate
          the content entirely. With just a click, you'll also receive excellent
          suggestions for both the title and meta description of your content.
        </ContentDesc>
        <div style={{ height: 40 }} />
        <StaticImage
          quality={100}
          src={"../images/outline-builder.png"}
          alt={"rank-tracking"}
          height={"100%"}
          layout="constrained"
          style={{ borderRadius: "12px" }}
        />
      </ContentBuilder>
    </BuilderBackground>
  )
}
const BuilderBackground = styled.div`
  background-color: #fff;
`
const ContentTitle = styled.h2`
  color: ${({ theme }) => theme.colors.primary};
  font-size: clamp(1.3rem, 1.8vw, 2rem);
  line-height: 1.2;
  width: 100%;
  margin-bottom: 1.575rem;
  font-family: InterBold;
`
const ContentDesc = styled.span`
  line-height: 1.6;
  font-family: Inter;
  font-size: clamp(0.9rem, 1.5vw, 1.1rem);
  letter-spacing: 0.15px;
  width: 100%;
  color: ${({ theme }) => theme.colors.fontBlack};
`
const ContentBuilder = styled.div`
  max-width: 1280px;
  margin: 0 auto;

  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4% 0px 4% 0px;
  flex-direction: column;

  @media (max-width: 1334px) {
    padding-left: 10%;
    padding-right: 10%;
  }

  @media (max-width: 623px) {
  }
`
const Background = styled.div`
  background: #fff;
`

const ListFooter = styled.div`
  padding: 50px 0px 30px 0px;
`
const StyledText = styled.p`
  color: ${({ theme }) => theme.colors.headlineBlack};
`
const StyledTitle = styled.h3`
  color: ${({ theme }) => theme.colors.headlineBlack};
  font-family: InterBold;
`
const HeadingContainer = styled.div`
  width: 100%;
  max-width: 1280px;

  @media (max-width: 1334px) {
    padding-left: 10%;
    padding-right: 10%;
    width: inherit;
  }
`

const MainWrapper = styled.section`
  height: 100%;
  max-width: 100%;
  padding: 4% 0px 20px 0px;
  max-width: 1280px;
  margin: 0 auto !important;
  float: none !important;

  @media (max-width: 1334px) {
    padding-left: 10%;
    padding-right: 10%;
  }
`

const Header = styled.section`
  margin-top: 4rem;
  align-items: start;
  background: linear-gradient(
    91deg,
    #212121,
    rgba(26, 26, 26, 0.78) 62%,
    transparent
  );
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  overflow: hidden;
  position: relative;
  flex-direction: column;
`

const Title = styled.h1`
  font-size: clamp(2rem, 5.5vw, 3.75rem);
  font-family: InterBold;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.colors.primary};
`

const Desc = styled.span`
  font-family: Inter500;
  font-size: clamp(1.1rem, 2.5vw, 1.6rem);
  line-height: 1.4;
  max-width: 850px;
  color: #fff;
  width: 80%;

  @media (min-width: 952px) {
    width: 100%;
  }
`

const FirstSection = styled.div`
  display: flex;
  position: relative;
  padding-bottom: 3rem;
`

const LeftDiv = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 3;
`

const HeadingWrapper = styled.div`
  margin-bottom: 1.575rem;
`

const Heading = styled.span`
  color: ${({ theme }) => theme.colors.headlineBlack};
  font-size: clamp(1.3rem, 1.8vw, 1.67rem);
  line-height: 1.2;
  margin-bottom: 1.575rem;
  font-family: InterBold;
`

const StyledHeading = styled(Heading)`
  color: ${({ theme }) => theme.colors.primary};
`
const ListTitle = styled.h1``
const List = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  list-style: none;
  gap: 1rem;
`

const ListItems = styled.li`
  &:before {
    color: #1cc695;
    content: "• ";
  }
`

const Span = styled.span`
  margin-left: 16px;
  font-family: Inter;
  font-size: clamp(0.9rem, 1.5vw, 1.2rem);
  font-weight: 300;
  color: ${({ theme }) => theme.colors.headlineBlack};
  letter-spacing: 0.15px;
`
const MountainDiv = styled.div`
  position: absolute;
  top: -8%;
  right: 0;

  @media (max-width: 928px) {
    display: none;
  }

  @media (max-width: 1506px) {
    opacity: 0.3;
  }
`

export const query = graphql`
  query ImageQuery {
    images: allMarkdownRemark {
      nodes {
        frontmatter {
          alt
          desc
          height
          img {
            childImageSharp {
              gatsbyImageData
            }
          }
          reverse
          width
          title
          order
        }
      }
    }
    mountain: file(relativePath: { eq: "mountain.webp" }) {
      childImageSharp {
        gatsbyImageData(width: 440, placeholder: BLURRED)
      }
    }
    detailImage: file(relativePath: { eq: "Detail4.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED)
      }
    }
    features: file(relativePath: { eq: "features-background.webp" }) {
      childImageSharp {
        gatsbyImageData(webpOptions: { quality: 100 }, placeholder: BLURRED)
      }
    }
  }
`
